export const hash = (message: string | Uint8Array) => {
  if (typeof message === 'string') {
    message = new TextEncoder().encode(message);
  }

  const table = [];
  const poly = 0xEDB88320;
  let result = 0xFFFFFFFF;

  for (let i = 0; i < 256; i++) {
    let u = i;
    for (let j = 0; j < 8; j++) {
      if (u & 0x1) {
        u = (u >>> 1) ^ poly;
      } else {
        u >>>= 1;
      }
    }
    table.push(u >>> 0);
  }

  for (let i = 0; i < message.length; i++) {
    result = ((result >>> 8) ^ table[message[i] ^ (result & 0xFF)]) >>> 0;
  }
  return ((~result) >>> 0).toString(16).padStart(8, '0');
};
