import { defineReference } from './definition';

defineReference({
  WebGLRenderingContext: {
    methods: {
      getParameter: [
        {
          name: 'MAX_VIEWPORT_DIMS',
          description: 'ビューポートの最大の大きさを取得します。',
          return: 'Int32Array',
          parameters: [
            { name: 'pname', type: 'gl.MAX_VIEWPORT_DIMS' },
          ],
        },
        {
          name: 'VIEWPORT',
          description: '現在設定されているビューポートを取得します。',
          return: 'Int32Array',
          parameters: [
            { name: 'pname', type: 'gl.VIEWPORT' },
          ],
        },
        {
          name: 'SCISSOR_BOX',
          description: '現在設定されているシザーボックスを取得します。',
          return: 'Int32Array',
          parameters: [
            { name: 'pname', type: 'gl.SCISSOR_BOX' },
          ],
          cf: ['gl.SCISSOR_TEST'],
        },
        {
          name: 'VERTEX_ARRAY_BINDING',
          description: '現在バインドされているVAOを取得します。',
          return: ['WebGLVertexArrayObject', 'null'],
          parameters: [
            { name: 'pname', type: 'gl.VERTEX_ARRAY_BINDING' },
          ],
          cf: ['WebGL2RenderingContext/bindVertexArray'],
          webgl2: true,
        },
        {
          name: 'FRONT_FACE',
          return: ['gl.CW', 'gl.CCW'],
          parameters: [
            { name: 'pname', type: 'gl.FRONT_FACE' },
          ],
        },
        {
          name: 'COLOR_CLEAR_VALUE',
          return: ['Float32Array'],
          parameters: [
            { name: 'pname', type: 'gl.COLOR_CLEAR_VALUE' },
          ],
        },
        {
          name: 'DEPTH_CLEAR_VALUE',
          return: ['GLfloat'],
          parameters: [
            { name: 'pname', type: 'gl.DEPTH_CLEAR_VALUE' },
          ],
        },
        {
          name: 'STENCIL_CLEAR_VALUE',
          return: ['GLint'],
          parameters: [
            { name: 'pname', type: 'gl.STENCIL_CLEAR_VALUE' },
          ],
        },
        {
          name: 'COLOR_WRITEMASK',
          return: ['Array'],
          parameters: [
            { name: 'pname', type: 'gl.COLOR_WRITEMASK' },
          ],
        },
      ],
    },
  },
});
