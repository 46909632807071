import { defineReference } from './definition';

defineReference({
  WebGLRenderingContext: {
    methods: {
      enable: [
        {
          name: 'SCISSOR_TEST',
          parameters: [
            { name: 'cap', type: 'gl.SCISSOR_TEST' },
          ],
        },
        {
          name: 'DITHER',
          parameters: [
            { name: 'cap', type: 'gl.DITHER' },
          ],
        },
        {
          name: 'RASTERIZER_DISCARD',
          parameters: [
            { name: 'cap', type: 'gl.RASTERIZER_DISCARD' },
          ],
          webgl2: true,
        },
      ],
      disable: [
        {
          name: 'SCISSOR_TEST',
          parameters: [
            { name: 'cap', type: 'gl.SCISSOR_TEST' },
          ],
        },
        {
          name: 'DITHER',
          parameters: [
            { name: 'cap', type: 'gl.DITHER' },
          ],
        },
        {
          name: 'RASTERIZER_DISCARD',
          parameters: [
            { name: 'cap', type: 'gl.RASTERIZER_DISCARD' },
          ],
          webgl2: true,
        },
      ],
      isEnabled: [
        {
          name: 'SCISSOR_TEST',
          return: ['GLboolean'],
          parameters: [
            { name: 'cap', type: 'gl.SCISSOR_TEST' },
          ],
        },
        {
          name: 'DITHER',
          return: ['GLboolean'],
          parameters: [
            { name: 'cap', type: 'gl.DITHER' },
          ],
        },
        {
          name: 'RASTERIZER_DISCARD',
          return: ['GLboolean'],
          parameters: [
            { name: 'cap', type: 'gl.RASTERIZER_DISCARD' },
          ],
          webgl2: true,
        },
      ],
    },
  },
});
