import { defineReference } from './definition';

defineReference({
  WebGL2RenderingContext: {
    methods: {
      blitFramebuffer: [{
        description: 'フレームバッファからフレームバッファへピクセルをコピーします。',
        parameters: [
          { name: 'srcX0', type: ['GLint'] },
          { name: 'srcY0', type: ['GLint'] },
          { name: 'srcX1', type: ['GLint'] },
          { name: 'srcY1', type: ['GLint'] },
          { name: 'dstX0', type: ['GLint'] },
          { name: 'dstY0', type: ['GLint'] },
          { name: 'dstX1', type: ['GLint'] },
          { name: 'dstY1', type: ['GLint'] },
          { name: 'mask', type: ['gl.COLOR_BUFFER_BIT', 'gl.DEPTH_BUFFER_BIT', 'gl.STENCIL_BUFFER_BIT'] },
          { name: 'filter', type: ['gl.NEAREST', 'gl.LINEAR'] },
        ],
        cf: ['gl.READ_FRAMEBUFFER', 'gl.DRAW_FRAMEBUFFER'],
      }],
    },
  },
});
