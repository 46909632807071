
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  props: {
    name: String,
  },
  setup () {
    onMounted(() => {
      if (!document.querySelector('link[rel="stylesheet"][href="https://fonts.googleapis.com/icon?family=Material+Icons"]')) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
        document.head.appendChild(link);
      }
    });
  },
});
