import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from './Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:objectName',
    name: 'Object',
    props: true,
    component: () => import('./reference/Object.vue'),
  },
  {
    path: '/:objectName/:methodName',
    name: 'Method',
    props: route => ({
      objectName: route.params.objectName,
      methodName: route.params.methodName,
      filter: route.query.filter,
    }),
    component: () => import('./reference/Method.vue'),
  },
  {
    path: '/:objectName/:methodName/:declarationID',
    name: 'Declaration',
    props: true,
    component: () => import('./reference/Declaration.vue'),
  },
  {
    // Not Found
    path: '/:pathMatch(.*)*',
    component: () => import('@/components/Page.vue'),
    props: {
      error: 404,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
