import { defineReference } from './definition';

defineReference({
  WebGLRenderingContext: {
    methods: {
      viewport: [{
        description: 'ビューポートを設定します。',
        parameters: [
          { name: 'x', type: 'GLint' },
          { name: 'y', type: 'GLint' },
          { name: 'width', type: 'GLsizei' },
          { name: 'height', type: 'GLsizei' },
        ],
        cf: [
          'WebGLRenderingContext/getParameter/MAX_VIEWPORT_DIMS',
          'WebGLRenderingContext/getParameter/VIEWPORT',
        ],
      }],
      scissor: [{
        description: 'シザーボックスを設定します。',
        parameters: [
          { name: 'x', type: 'GLint' },
          { name: 'y', type: 'GLint' },
          { name: 'width', type: 'GLsizei' },
          { name: 'height', type: 'GLsizei' },
        ],
        cf: [
          'WebGLRenderingContext/getParameter/SCISSOR_BOX',
          'gl.SCISSOR_TEST',
        ],
      }],
      frontFace: [{
        parameters: [
          { name: 'mode', type: ['gl.CW', 'gl.CCW'] },
        ],
        cf: [
          'WebGLRenderingContext/getParameter/FRONT_FACE',
        ],
      }],
      flush: [{
        description: '可能な限り迅速にコマンドバッファ内のすべてのコマンドを実行し、コマンドバッファを空にすることを要求します。',
        parameters: [],
      }],
      finish: [{
        description: 'これまでに呼び出されたコマンドがすべて完了しするまで処理をブロックします。',
        parameters: [],
      }],
      clear: [{
        parameters: [
          { name: 'mask', type: ['gl.COLOR_BUFFER_BIT', 'gl.DEPTH_BUFFER_BIT', 'gl.STENCIL_BUFFER_BIT' ] },
        ],
      }],
      clearColor: [{
        parameters: [
          { name: 'red', type: ['GLclampf'] },
          { name: 'green', type: ['GLclampf'] },
          { name: 'blue', type: ['GLclampf'] },
          { name: 'alpha', type: ['GLclampf'] },
        ],
        cf: [ 'WebGLRenderingContext/clear', 'WebGLRenderingContext/getParameter/COLOR_CLEAR_VALUE' ],
      }],
      clearDepth: [{
        parameters: [
          { name: 'depth', type: ['GLclampf'] },
        ],
        cf: [ 'WebGLRenderingContext/clear', 'WebGLRenderingContext/getParameter/DEPTH_CLEAR_VALUE', 'gl.DEPTH_TEST' ],
      }],
      clearStencil: [{
        parameters: [
          { name: 's', type: ['GLint'] },
        ],
        cf: [ 'WebGLRenderingContext/clear', 'WebGLRenderingContext/getParameter/STENCIL_CLEAR_VALUE', 'gl.STENCIL_TEST' ],
      }],
      colorMask: [{
        parameters: [
          { name: 'red', type: ['GLboolean'] },
          { name: 'green', type: ['GLboolean'] },
          { name: 'blue', type: ['GLboolean'] },
          { name: 'alpha', type: ['GLboolean'] },
        ],
        cf: [ 'WebGLRenderingContext/getParameter/COLOR_WRITEMASK' ],
      }],
    },
  },
});
