import { defineReference } from './definition';

defineReference({
  WebGL2RenderingContext: {
    methods: {
      createVertexArray: [{
        parameters: [],
        return: ['WebGLVertexArrayObject'],
      }],
      deleteVertexArray: [{
        parameters: [
          { name: 'vertexArray', type: 'WebGLVertexArrayObject' },
        ],
      }],
      isVertexArray: [{
        return: ['GLboolean'],
        parameters: [
          { name: 'vertexArray', type: 'WebGLVertexArrayObject' },
        ],
      }],
      bindVertexArray: [{
        parameters: [
          { name: 'vertexArray', type: 'WebGLVertexArrayObject' },
        ],
      }],
    },
  },
});
