export const checkExists = (object: string, method?: string) => {
  if (method === undefined) {
    if (object.startsWith('GL')) {
      return undefined;
    }
    if (object.startsWith('gl.')) {
      const name = object.slice(3);
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        webgl: Boolean((WebGLRenderingContext as any)[name]),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        webgl2: Boolean(window.WebGL2RenderingContext) && Boolean((WebGL2RenderingContext as any)[name]),
      };
    }
    return {
      window: Boolean(window[object as keyof Window]),
    };
  } else {
    if (object === 'WebGLRenderingContext' || object == 'WebGL2RenderingContext') {
      const glCanvas = document.createElement('canvas');
      const gl2Canvas = document.createElement('canvas');
      const gl = glCanvas.getContext('webgl') || undefined;
      const gl2 = gl2Canvas.getContext('webgl2') || undefined;
      return {
        webgl: Boolean(gl && gl[method as keyof WebGLRenderingContext]),
        webgl2: Boolean(gl2 && gl2[method as keyof WebGL2RenderingContext]),
      };
    }
    // TODO
    return undefined;
  }
};
