export { checkExists } from './check-exists';

import './gl2';
import './misc';
import './sampler';
import './vertex-array-object';
import './frame-buffer';
import './texture';

import './get-parameter';
import './enable';

import { unifyReference, reference } from './definition';

unifyReference();
// console.log(reference);

export {
  reference,
};

