import { defineReference } from './definition';

defineReference({
  WebGL2RenderingContext: {
    methods: {
      createSampler: [{
        return: ['WebGLSampler'],
        parameters: [],
      }],
      deleteSampler: [{
        parameters: [
          { name: 'sampler', type: ['WebGLSampler'] },
        ],
      }],
      bindSampler: [{
        parameters: [
          { name: 'unit', type: ['GLuint'] },
          { name: 'sampler', type: ['WebGLSampler'] },
        ],
      }],
      isSampler: [{
        return: ['GLboolean'],
        parameters: [
          { name: 'sampler', type: ['WebGLSampler'] },
        ],
      }],
      samplerParameteri: [
        {
          name: 'TEXTURE_COMPARE_FUNC',
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_COMPARE_FUNC'] },
            { name: 'param', type: ['gl.LEQUAL', 'gl.GEQUAL', 'gl.LESS', 'gl.GREATER', 'gl.EQUAL', 'gl.NOTEQUAL', 'gl.ALWAYS', 'gl.NEVER'] },
          ],
        },
        {
          name: 'TEXTURE_COMPARE_MODE',
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_COMPARE_MODE'] },
            { name: 'param', type: ['gl.NONE', 'gl.COMPARE_REF_TO_TEXTURE'] },
          ],
        },
        {
          name: 'TEXTURE_MAG_FILTER',
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_MAG_FILTER'] },
            { name: 'param', type: ['gl.LINEAR', 'gl.NEAREST'] },
          ],
        },
        {
          name: 'TEXTURE_MIN_FILTER',
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_MIN_FILTER'] },
            { name: 'param', type: ['gl.LINEAR', 'gl.NEAREST', 'gl.NEAREST_MIPMAP_NEAREST', 'gl.LINEAR_MIPMAP_NEAREST', 'gl.NEAREST_MIPMAP_LINEAR', 'gl.LINEAR_MIPMAP_LINEAR'] },
          ],
        },
        {
          name: 'TEXTURE_WRAP',
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_WRAP_R', 'gl.TEXTURE_WRAP_S', 'gl.TEXTURE_WRAP_T'] },
            { name: 'param', type: ['gl.REPEAT', 'gl.CLAMP_TO_EDGE', 'gl.MIRRORED_REPEAT'] },
          ],
        },
      ],
      samplerParameterf: [{
        parameters: [
          { name: 'sampler', type: ['WebGLSampler'] },
          { name: 'pname', type: ['gl.MAX_LOD', 'gl.MIN_LOD'] },
          { name: 'param', type: ['GLfloat'] },
        ],
        cf: ['WebGL2RenderingContext/getSamplerParameter/LOD'],
      }],
      getSamplerParameter: [
        {
          name: 'TEXTURE_COMPARE_FUNC',
          return: ['gl.LEQUAL', 'gl.GEQUAL', 'gl.LESS', 'gl.GREATER', 'gl.EQUAL', 'gl.NOTEQUAL', 'gl.ALWAYS', 'gl.NEVER'],
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_COMPARE_FUNC'] },
          ],
          cf: ['WebGL2RenderingContext/samplerParameteri/TEXTURE_COMPARE_FUNC'],
        },
        {
          name: 'TEXTURE_COMPARE_MODE',
          return: ['gl.NONE', 'gl.COMPARE_REF_TO_TEXTURE'],
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_COMPARE_MODE'] },
          ],
          cf: ['WebGL2RenderingContext/samplerParameteri/TEXTURE_COMPARE_MODE'],
        },
        {
          name: 'TEXTURE_MAG_FILTER',
          return: ['gl.LINEAR', 'gl.NEAREST'],
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_MAG_FILTER'] },
          ],
          cf: ['WebGL2RenderingContext/samplerParameteri/TEXTURE_MAG_FILTER'],
        },
        {
          name: 'TEXTURE_MIN_FILTER',
          return: ['gl.LINEAR', 'gl.NEAREST', 'gl.NEAREST_MIPMAP_NEAREST', 'gl.LINEAR_MIPMAP_NEAREST', 'gl.NEAREST_MIPMAP_LINEAR', 'gl.LINEAR_MIPMAP_LINEAR'],
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_MIN_FILTER'] },
          ],
          cf: ['WebGL2RenderingContext/samplerParameteri/TEXTURE_MIN_FILTER'],
        },
        {
          name: 'TEXTURE_WRAP',
          return: ['gl.REPEAT', 'gl.CLAMP_TO_EDGE', 'gl.MIRRORED_REPEAT'],
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.TEXTURE_WRAP_R', 'gl.TEXTURE_WRAP_S', 'gl.TEXTURE_WRAP_T'] },
          ],
          cf: ['WebGL2RenderingContext/samplerParameteri/TEXTURE_WRAP'],
        },
        {
          name: 'LOD',
          return: ['GLfloat'],
          parameters: [
            { name: 'sampler', type: ['WebGLSampler'] },
            { name: 'pname', type: ['gl.MAX_LOD', 'gl.MIN_LOD'] },
          ],
        },
      ],
    },
  },
});
