
import { defineComponent } from 'vue';
import { reference } from '@/reference';

export default defineComponent({
  setup () {
    return {
      reference,
    };
  },
});
