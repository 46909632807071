
import { defineComponent, watch } from 'vue';

export default defineComponent({
  props: {
    title: String,
    error: [String, Number],
  },
  setup (props) {
    watch(() => props.title, title => {
      if (Number(props.error ?? 0) === 0) {
        document.title = title ? `${title} | d3va::webgl2` : 'd3va::webgl2';
      }
    }, { immediate: true });

    watch(() => props.error, error => {
      const code = Number(error ?? 0);
      if (code === 404) {
        document.title = '404 Not Found | d3va::webgl2';
      } else if (code !== 0) {
        document.title = 'Error | d3va::webgl2';
      }
    }, { immediate: true });
  },
});
