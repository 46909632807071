import { hash } from './hash';

export const reference: {
  [object: string]: {
    description?: string;
    methods: {
      [method: string]: {
        description?: string;
        cf: string[];
        webgl2?: true;

        declarations: {
          [id: string]: {
            name?: string;
            description?: string;
            cf: string[];
            return: string[];
            parameters: {
              name: string;
              type: string[];
              description?: string;
            }[],
            refs: string[];
            webgl2?: true;
          };
        };
      };
    };
    webgl2?: true;
    cf: string[];
    backrefs: {
      [object: string]: {
        [method: string]: string[];
      };
    };
  };
} = {};

const getObject = (name: string) => {
  if (!(name in reference)) {
    reference[name] = {
      methods: {},
      cf: [],
      backrefs: {},
    };
  }
  return reference[name];
};

const cfCache: { target: string; ref: string; error: Error }[] = [];

const addCf = (target: string, ref: string, error?: Error) => {
  const split = target.split('/');
  if (split.length === 0 || split.length > 3) {
    console.error(Error(`unknown reference target: ${target}`));
  } else {
    const [objectName, methodName, declarationID] = split;

    if (methodName === undefined) {
      getObject(objectName).cf = [...new Set([...getObject(objectName).cf, ref])];
    } else  {
      const object = getObject(objectName);
      if (!(methodName in object.methods)) {
        object.methods[methodName] = {
          cf: [],
          declarations: {},
        };
      }
      const method = object.methods[methodName];
      if (declarationID === undefined) {
        method.cf = [...new Set([...method.cf, ref])];
      } else {
        if (declarationID in method.declarations) {
          method.declarations[declarationID].cf = [...new Set([...method.declarations[declarationID].cf, ref])];
        } else {
          cfCache.push({
            target, ref,
            error: error ?? Error(`unknown cf. target: ${target} (ref: ${ref})`),
          });
        }
      }
    }
  }
};

export const unifyReference = () => {
  const splice = cfCache.splice(0, cfCache.length);
  splice.forEach(x => addCf(x.target, x.ref));
  cfCache.forEach(({ error }) => console.error(error));

  Object.values(reference).forEach(object => Object.entries(object.methods).forEach(([methodName, method]) => {
    if (Object.values(method.declarations).length === 0 && method.cf.length === 0 && !method.description) {
      delete object.methods[methodName];
    }
  }));

  Object.entries(reference).forEach(([objectName, object]) => {
    if (Object.values(object.backrefs).length === 0 && object.cf.length === 0 && Object.values(object.methods).length === 0 && !object.description) {
      delete reference[objectName];
    }
  });

  const objectSort = <T extends { [key: string]: unknown }>(object: T) => {
    const entries = Object.entries(object);
    entries.forEach(([key]) => delete object[key]);
    entries.sort(([a], [b]) => a.localeCompare(b));
    entries.forEach(([key, value]) => Object.assign(object, { [key]: value }));
  };

  objectSort(reference);

  Object.values(reference).forEach(x => {
    objectSort(x.methods);
  });
};

export const defineReference = <T extends {
  [object: string]: {
    description?: string;
    webgl2?: true;
    cf?: string[];
    methods?: {
      [method: string]: (({
        name?: string;
        return?: string | string[];
        parameters: {
          name: string;
          type: string | string[];
          description?: string;
        }[];
      } | {
        root: true;
      }) & {
        description?: string;
        cf?: string[];
        webgl2?: true;
      })[];
    };
  };
}> (definition: T) => {
  Object.entries(definition).forEach(([objectName, methods]) => {
    const object = getObject(objectName);

    if (object.description && methods.description) {
      console.error(Error(`description conflict: ${objectName}`));
    } else if (methods.description) {
      object.description = methods.description;
    }

    if (methods.webgl2) {
      object.webgl2 = true;
    }

    if (methods.cf) {
      object.cf = [...new Set([...object.cf, ...methods.cf])];
      methods.cf.forEach(cf => addCf(cf, objectName));
    }

    if (methods.methods) {
      Object.entries(methods.methods).forEach(([methodName, declarations]) => {
        if (!(methodName in object.methods)) {
          object.methods[methodName] = {
            cf: [],
            declarations: {},
          };
        }
        const method = object.methods[methodName];

        declarations.forEach(declaration => {
          if ('root' in declaration) {
            if (method.description && declaration.description) {
              // ERROR
              console.error(Error(`description conflict: ${objectName}.${methodName}`));
            } else if (declaration.description) {
              method.description = declaration.description;
            }
            if (declaration.webgl2) {
              method.webgl2 = true;
            }
            const cf = (declaration.cf ?? []).filter(x => !method.cf.includes(x));
            method.cf.push(...cf);
            cf.forEach(x => addCf(x, `${objectName}/${methodName}`));
          } else {
            const id = declaration.name ?? hash(JSON.stringify({
              return: declaration.return ? (Array.isArray(declaration.return) ? declaration.return : [declaration.return]) : [],
              parameters: declaration.parameters.map(x => ({ name: x.name, type: Array.isArray(x.type) ? x.type : [x.type] })),
            }));

            if (id in method.declarations) {
              console.error(Error(`conflict method: ${objectName}.${methodName}#${id}`));
            } else {
              const _return = declaration.return ? (Array.isArray(declaration.return) ? declaration.return : [declaration.return]) : [];
              const parameters = declaration.parameters.map(x => ({ name: x.name, type: Array.isArray(x.type) ? x.type : [x.type], description: x.description }));
              method.declarations[id] = {
                return: _return,
                parameters,
                description: declaration.description,
                name: declaration.name,
                cf: declaration.cf ?? [],
                refs: [...new Set([..._return, ...parameters.flatMap(x => x.type)])],
                webgl2: declaration.webgl2,
              };
              method.declarations[id].cf.forEach(x => addCf(x, `${objectName}/${methodName}/${id}`));
              method.declarations[id].refs.forEach(type => {
                const object = getObject(type);
                if (!(objectName in object.backrefs)) {
                  object.backrefs[objectName] = {};
                }
                if (!(methodName in object.backrefs[objectName])) {
                  object.backrefs[objectName][methodName] = [];
                }
                object.backrefs[objectName][methodName] = [...new Set([...object.backrefs[objectName][methodName], id])];
              });
            }
          }
        });
      });
    }
  });
  return definition;
};
