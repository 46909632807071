
import { defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    src: {
      required: true,
      type: [String, Function, Object] as PropType<string | (() => Promise<string | { default: string }>) | Promise<string | { default: string }>>,
    },
  },
  setup (props) {
    const srcValue = ref<string>();

    watch(() => props.src, async src => {
      srcValue.value = undefined;
      if (src) {
        if (typeof src === 'string') {
          srcValue.value = src;
        } else {
          const result = await (typeof src === 'function' ? src() : src).then(x => typeof x === 'string' ? x : x.default);
          if (props.src === src) {
            srcValue.value = result;
          }
        }
      }
    }, { immediate: true });

    return {
      srcValue,
    };
  },
});
