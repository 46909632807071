import { defineReference } from './definition';

defineReference({
  WebGLRenderingContext: {
    methods: {
      createTexture: [{
        parameters: [],
        return: ['WebGLTexture'],
      }],
      deleteTexture: [{
        parameters: [
          { name: 'texture', type: ['WebGLTexture' ] },
        ],
      }],
      isTexture: [{
        parameters: [
          { name: 'texture', type: ['WebGLTexture' ] },
        ],
        return: ['GLboolean'],
      }],
      generateMipmap: [{
        parameters: [
          { name: 'target', type: ['gl.TEXTURE_2D', 'gl.TEXTURE_CUBE_MAP', 'gl.TEXTURE_3D', 'gl.TEXTURE_2D_ARRAY'] },
        ],
        cf: ['WebGLTexture'],
      }],
      activeTexture: [{
        parameters: [
          { name: 'texture', type: new Array(16).fill(undefined).map((_, i) => `gl.TEXTURE${i}`) },
        ],
        cf: [
          'WebGLTexture',
          'WebGLRenderingContext/getParameter/ACTIVE_TEXTURE',
        ],
      }],
      bindTexture: [{
        parameters: [
          { name: 'target', type: ['gl.TEXTURE_2D', 'gl.TEXTURE_CUBE_MAP', 'gl.TEXTURE_3D', 'gl.TEXTURE_2D_ARRAY'] },
          { name: 'texture', type: ['WebGLTexture', 'null'] },
        ],
      }],
      getParameter: [
        {
          name: 'TEXTURE_BINDING',
          return: ['WebGLTexture', 'null'],
          parameters: [
            { name: 'pname', type: ['gl.TEXTURE_BINDING_2D', 'gl.TEXTURE_BINDING_CUBE_MAP', 'gl.TEXTURE_BINDING_2D_ARRAY', 'gl.TEXTURE_BINDING_3D'] },
          ],
        },
        {
          name: 'ACTIVE_TEXTURE',
          return: new Array(16).fill(undefined).map((_, i) => `gl.TEXTURE${i}`),
          parameters: [
            { name: 'pname', type: ['gl.ACTIVE_TEXTURE'] },
          ],
          cf: ['WebGLTexture'],
        },
      ],
    },
  },
});
