
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  setup () {
    onMounted(() => {
      const meta = [
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
      ];

      meta.forEach(data => {
        const element = document.createElement('meta');
        Object.entries(data).forEach((([key, value]) => element.setAttribute(key, value)));
        document.head.appendChild(element);
      });
    });
  },
});
