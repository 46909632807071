import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8cf1b8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.name)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        title: _ctx.name
      }, _toDisplayString(_ctx.name), 9, _hoisted_1))
    : _createCommentVNode("", true)
}